<template>
  <div class="optional-items-component">
    <b-row v-if="!loaded" class="pt-4 mt-4" style="min-height: 140px;">
      <ct-centered-spinner />
    </b-row>
    <template v-else-if="showOptionalItems">
      <h3>
        Optional Items
      </h3>

      <div class="my-4">
        <template v-for="(item, index) in optionalCategoryProducts">
          <template v-if="showSubProducts(item)">
            <b-checkbox
              :key="item.displayName"
              class="mt-2"
              switch
              @change="categoryWithSubProductSelected($event, index)"
            >
              {{ displayNameAndPrice(item, true) }}

              <fa-icon
                v-if="item.description"
                v-b-popover.hover.right="item.description"
                class="question-circle pl-1 help-text"
                icon="question-circle"
                size="1x"
              />
            </b-checkbox>

            <div :id="`subProducts-${index}`" :key="index" style="display:none" class="ml-4">
              <b-checkbox
                v-for="subItem in item.products"
                :key="subItem.id"
                class="mt-2"
                :checked="isIncluded(subItem)"
                :disabled="optionalItemDisabled(subItem)"
                switch
                @change="selectItem(subItem, $event)"
              >
                {{ displayNameAndPrice(subItem, true) }}

                <fa-icon
                  v-if="subItem.description"
                  v-b-popover.hover.right="subItem.description"
                  class="question-circle pl-1 help-text"
                  icon="question-circle"
                  size="1x"
                />
              </b-checkbox>
            </div>
          </template>

          <template v-else-if="item.optionalFilingMethods">
            <b-checkbox
              v-if="item.optionalFilingMethods"
              :key="item.displayName"
              :style="{ display: showOptionalFilingMethods(item) ? 'block' : 'none' }"
              class="mt-2"
              switch
              @change="additionalCategorySelected(item, $event)"
            >
              {{ displayNameAndPrice(item, true) }}

              <fa-icon
                v-if="item.description"
                v-b-popover.hover.right="item.description"
                class="question-circle pl-1 help-text"
                icon="question-circle"
                size="1x"
              />
            </b-checkbox>

            <div
              id="disclaimer"
              :key="item.filing_name"
              style="display:none; margin-left: 4rem;"
            >
              <hr>
              <em>
                <small>
                  <b>{{ disclaimerText }}</b>
                </small>
              </em>
              <hr>
            </div>

            <div
              v-if="showOptionalFilingMethods(item)"
              id="localJurisdictionSelection"
              :key="item.stateJurisdiction"
              style="display:none; margin-left: 4rem;"
            >
              <multi-select
                v-model="localJurisdictionSelection"
                :multiple="true"
                :placeholder="`Choose the cities / counties to file ${item.displayName}`"
                :options="item.optionalFilingMethods"
                label="local_jurisdiction"
                track-by="name"
                @select="option => selectedLocalJurisdiction(item, option)"
                @remove="option => removedLocalJurisdiction(item, option)"
              >
                <template slot="option" slot-scope="props">
                  {{ props.option.local_jurisdiction + ' - $' + (item.price + props.option.cost) }}
                </template>
              </multi-select>
              <small>* If your city/county is not listed in the dropdown above, please email us so
                we can determine if it can be added and facilitate the filing process for you.</small>
              <hr>
            </div>
          </template>

          <b-checkbox
            v-else-if="showOptionalFilingMethods(item)"
            :key="index"
            class="mt-2"
            :checked="isIncluded(item)"
            :disabled="optionalItemDisabled(item)"
            switch
            @change="item.stateJurisdiction ? additionalCategorySelected(item, $event) : selectItem(item, $event)"
          >
            {{ displayNameAndPrice(item, true) }}

            <fa-icon
              v-if="item.description"
              v-b-popover.hover.right="item.description"
              class="question-circle pl-1 help-text"
              icon="question-circle"
              size="1x"
            />

            <div
              v-if="item.stateJurisdiction"
              id="disclaimer"
              style="display: none; margin-left: 4rem;"
            >
              <hr>
              <em>
                <small>
                  <b>{{ disclaimerText }}</b>
                </small>
              </em>
              <hr>
            </div>
          </b-checkbox>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'
import { loadOptionalCategoryProducts, optionalItemSelected } from '../../components/HireUs/optionalItemHelper'
import { dbaJurisdictionDisclaimers } from '@/common/modules/jurisdictionDisclaimers'

export default {
  name: 'OptionalItems',

  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    MultiSelect,
  },
  props: {
    companyId: String,
    productKind: String,
    productId: String,
    categoryId: String,
    product: Object,
  },

  data () {
    return {
      selected: [],
      currentProduct: null,
      currentProductCartItem: null,
      loaded: false,
      optionalCategoryProducts: [],
      taxId: {
        ssn: 'ssn tax id',
        noSsn: 'no-ssn tax id',
      },
      displayCategorySubProducts: ['Open and Scan'],
      disclaimerText: '',
      localJurisdictionSelection: [],
    }
  },

  computed: {
    ...mapGetters('account', ['isAccountWholesaler']),
    ...mapGetters('checkout', [
      'cartItems',
      'productsLoaded',
      'allProducts',
      'productsByKind',
      'hireUsOptionalItemSetup',
      'monitoringOptionalItemSetup',
      'findCartItem',
      'ineligibleToOrderNonSSNTaxId',
      'ineligibleToOrderSSNTaxId',
      'formationProductInCart',
      'deduceCartItemJurisdiction',
    ]),
    ...mapGetters('products', [
      'optionalCategories',
    ]),
    ...mapGetters('monitoring', [
      'monitoringTrialEligible',
    ]),
    ...mapGetters('companies', {
      company: 'currentCompany',
      activeComplianceServiceInJurisdiction: 'activeComplianceServiceInJurisdiction',
    }),
    showOptionalItems () {
      return !!this.optionalCategoryProducts.length
    },
    hasRenewalServiceOptionalItem () {
      return this.optionalCategoryProducts.some(oi => oi.name === 'Renewal Service')
    },
    cartHasRegisteredAgentService() {
      return this.cartItems.some((ci) => ci.display_info.product_category === 'registered-agent')
    },
    cartHasComplianceIncludedWithRa() {
      return this.cartItems.some(cartItem => this.productsByKind('simple_product').find(product => product.id === cartItem.product_id && product?.metadata?.filing_included_with_ra))
    },
    hasOptionalCategoriesAndProducts () {
      return this.optionalCategories.length &&
        this.allProducts.length
    },
    monitoringProductShowToClient() {
      const monitoringProduct = this.optionalCategoryProducts.find((item)=> item.category === 'monitoring')

      return monitoringProduct?.show_to_client || false
    },
  },

  async mounted () {
    await this.ensureProductsLoaded()
    await this.ensureOptionalCategoriesLoaded()
    await this.determineTrialEligibilityMonitoring(this.company.id)
    this.$emit('toggleButtonVisibility', { backButtonHidden: false, addContactButtonHidden: true })

    this.currentProduct = this.allProducts.find(product => product.id === this.$route.params.productId)
    this.currentProductCartItem = this.findCartItem(this.currentProduct.id)
    this.setCurrentProduct(this.currentProduct)

    const skippedCategories = this.determineSkippedCategories()

    this.optionalCategoryProducts = this.hasOptionalCategoriesAndProducts ?
      loadOptionalCategoryProducts(this.optionalCategories, skippedCategories)[0] :
      []
    if (this.hasRenewalServiceOptionalItem) this.toggleRenewalService()
    if (this.monitoringTrialEligible) this.toggleMonitoringService()
    this.loaded = true
  },

  methods: {
    ...mapActions('checkout', [
      'loadProducts',
      'resolveOptionalItems',
      'setCurrentProduct',
    ]),
    ...mapActions('products', [
      'getOptionalCategories',
    ]),
    ...mapActions('monitoring', [
      'determineTrialEligibilityMonitoring',
    ]),
    async ensureProductsLoaded() {
      if (!this.productsLoaded) await this.loadProducts(this.companyId)
    },

    async ensureOptionalCategoriesLoaded() {
      if (!this.optionalCategories.length) await this.loadOptionalCategories()
    },

    toggleRenewalService () {
      const parentFilingName = this.currentProduct?.kind === 'filing_product' ? this.currentProduct.filing_name : ''
      this.resolveOptionalItems(
        {
          optionalAddOn: true,
          optionalParentFilingProduct: parentFilingName,
          optionalItemSetup: this.hireUsOptionalItemSetup,
        })
    },

    toggleMonitoringService() {
      let monitoringProduct = this.optionalCategoryProducts.find((item)=> item.category === 'monitoring')

      if (monitoringProduct === undefined) return
      if (this.monitoringProductShowToClient) {
        if (this.monitoringTrialEligible) {
          monitoringProduct.price = 0.00
        }

        optionalItemSelected(monitoringProduct, true)
      }
    },

    selectItem(item, selected) {
      if (item.category === 'monitoring' && this.monitoringTrialEligible) {
        item.price = 0.00
      }
      if (item.category === 'monitoring' && !this.monitoringProductShowToClient) return

      optionalItemSelected(item, selected)
      this.logInteraction(item?.name, selected)
    },

    logInteraction(productName, selected) {
      const name = `optional-item-${selected ? 'added' : 'removed'}`
      this.$root.$emit('log-interaction', { type: 'button', name: name, subName: productName })
    },

    isIncluded (item) {
      return !!this.findCartItem(item.id)
    },

    optionalItemDisabled (item) {
      if (item.filing_sub_name === this.taxId.noSsn) {
        return this.ineligibleToOrderNonSSNTaxId
      } else if (item.filing_sub_name === this.taxId.ssn) {
        return this.ineligibleToOrderSSNTaxId
      } else if (item.filing_name === 's corp') {
        return !this.formationProductInCart
      }
    },

    itemIsComplianceAndNeedsFiltered (item) {
      if (item.displayName === 'File Annual Report For Me') {
        if (this.cartHasRegisteredAgentService && this.cartHasComplianceIncludedWithRa && !this.isAccountWholesaler) {
          return true
        }
      }

      return false
    },

    async loadOptionalCategories () {
      if (this.categoryId && this.companyId) {
        const params = {
          companyId: this.companyId,
          categoryId: this.categoryId,
        }
        await this.getOptionalCategories(params)
      }
    },

    determineSkippedCategories () {
      let skippedCategories = []
      if (this.currentProduct.kind === 'filing_product') {
        skippedCategories = this.activeComplianceServiceInJurisdiction(
          this.deduceCartItemJurisdiction(this.currentProductCartItem)?.state_province_region
        ) ? ['File Annual Report For Me'] : []
      } else if (this.currentProduct.kind === 'registered_agent_product') {
        const raProducts = this.cartItems.filter(item => item.product_type === 'RegisteredAgentProduct')
        const activeComplianceCount = raProducts.reduce((count, product) => {
          return count + (this.activeComplianceServiceInJurisdiction(this.deduceCartItemJurisdiction(product)?.state_province_region) ? 1 : 0)
        }, 0)
        skippedCategories = (activeComplianceCount === raProducts.length) ? ['File Annual Report For Me'] : []
      } else if (this.currentProduct.kind === 'product_bundle') {
        const bundleItems = Object.values(this.currentProductCartItem.data)
        const bundleRAProduct = bundleItems.find(item => item.kind === 'registered_agent_product')
        if (bundleRAProduct) {
          skippedCategories = this.activeComplianceServiceInJurisdiction(bundleRAProduct.jurisdiction) ? ['File Annual Report For Me'] : []
        }
      }
      return skippedCategories
    },

    categoryWithSubProductSelected (event, index) {
      const subProductDiv = document.getElementById(`subProducts-${index}`)
      if (subProductDiv) subProductDiv.style.display = event ? 'block' : 'none'
    },

    showOptionalFilingMethods (item) {
      return item.defaultFilingMethod !== 'none' && !this.itemIsComplianceAndNeedsFiltered(item)
    },
    additionalCategorySelected (item, event) {
      if (!item.defaultFilingMethod?.local_jurisdiction_id) {
        this.selectItem(item, event)
      }
      else if (item.defaultFilingMethod?.local_jurisdiction_id) {
        const localJurisdictionDiv = document.getElementById('localJurisdictionSelection')
        localJurisdictionDiv.style.display = event ? 'block' : 'none'
      }

      this.additionalCategoryDisclaimer(item, event)
    },

    selectedLocalJurisdiction (item, filingMethod) {
      item.defaultFilingMethod = filingMethod
      this.selectItem(item, true)
    },
    removedLocalJurisdiction (item, filingMethod) {
      item.defaultFilingMethod = filingMethod
      this.selectItem(item, false)
    },

    additionalCategoryDisclaimer (item, event) {
      const jurisdictionDisclaimers = dbaJurisdictionDisclaimers

      if (jurisdictionDisclaimers[item.stateJurisdiction]) {
        this.disclaimerText = jurisdictionDisclaimers[item.stateJurisdiction]

        const disclaimerDiv = document.getElementById('disclaimer')
        disclaimerDiv.style.display = event ? 'block' : 'none'
      }
    },

    displayNameAndPrice (item, showPrice) {
      // Adjust pricing for monitoring
      if (item.category == 'monitoring' && this.monitoringTrialEligible) {
        return `${item.displayName} $0.00 (${item.displayPrice} monthly after Trial)`
      }
      const displayPrice = showPrice &&
        item?.name !== 'Renewal Service'
        && item.displayPrice &&
        item.kind !== 'product_bundle'

      return `${item.displayName} ${displayPrice ? ' - ' + item.displayPrice : ''}`
    },

    showSubProducts(item) {
      return item.kind !== 'product_bundle' && item?.products?.length
    },
  },
}
</script>

<style lang="scss">
.optional-items-component {
  .badge-success {
    background: $ct-ui-color-11;
    font-size: .9em;
  }

  .custom-switch .custom-control-label:before {
    background: #ddd;
    cursor: pointer;
    display: block;
    position: relative;
    float: left;
    top: -0.125rem;
    height: 1.875rem;
    width: 3.5rem;
    margin-right: -1.25rem;
    border-radius: 1rem;
    border: none;
    transition: all 250ms linear;
  }

  .custom-switch .custom-control-label:after {
    background: #fff;
    cursor: pointer;
    content: "";
    display: block;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 0.0625rem;
    margin-left: 0.125rem;
    border-radius: 100%;
    transition: all 250ms linear;
    z-index: 2;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.5rem);
  }
}
</style>
